//redux
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers/index';
import thunkmiddleware from 'redux-thunk';


//redux dev tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(thunkmiddleware)
    ));

export default store;