// msk lab data hub style theme

export const theme = {
    colors: {
        text: '#060A11',
        background: '#f2f2f2',
        primary: '#0683D0',
        darkerPrimary: '#076cab',
        darkestPrimary: '#055688',
        midnightPrimary: '#010e17',
        secondary: '#5f5f5f',
        darkerSecondary: '#4c4c4c',
        darkestSecondary: '#383838',
        muted: '#f6f6f9',
        invalid: '#df1f1f',
        white: '#fff',
        offWhite: '#f0f0f0',
        lightGray: '#e0e0e0',
        gray: '#808080',
        darkGray: '#444444',
        darkerGray: '#383838',
        darkestGray: '#292929',
        almostBlack: '#0e0e0e',
        blacker: '#0e0e0e',
        black: '#000',
        highlight: 'hsla(39, 85%, 49%, 0.13)',
        mustard: '#fdc051',
        darkMustard: '#e69b13',
    },
    fonts: {
        body: 'Lato, sans-serif',
        heading: 'inherit',
        monospace: 'Menlo, monospace',
    },
    fontSizes: [
        12, 13, 14, 15, 16, 17, 20, 24, 32, 48, 64, 96
    ],
    fontWeights: {
        body: 400,
        medium: 500,
        heading: 700,
        bold: 700,
    },
    lineHeights: {
        body: 1.5,
        heading: 1.25,
    },
    //mobile-first so minWidth pl: [0, 3] means 0-575px is 0px and 576px+ is space[3]px
    breakpoints: ['576px', '768px', '992px', '1200px'],
    space: [0, 4, 8, 12, 19, 32, 64, 128, 256, 512],
    sizes: {
        avatar: 48,
    },
    radii: {
        default: 0,
        circle: 99999,
    },
    shadows: {
        card: '0 2px 5px 0 rgba(0,0,0,0.12), 0 2px 10px 0 rgba(0,0,0,0.08)',
        button: '0 5px 11px 0 rgba(0, 0, 0, 0.10), 0 4px 15px 0 rgba(0, 0, 0, 0.08)',
        buttonHover: '0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15)',
    },
    gradiants: {
        hero: `linear-gradient(to right top, rgb(231, 235, 243), rgb(245, 252, 253))`,
    },
    // rebass variants
    text: {
        heading: {
            fontFamily: 'heading',
            lineHeight: 'heading',
            fontWeight: 'heading',
            my: 3,
            fontSize: 7
        },
        display: {
            fontFamily: 'heading',
            fontWeight: 'heading',
            lineHeight: 'heading',
            fontSize: [5, 6, 7],
        },
        caps: {
            textTransform: 'uppercase',
            letterSpacing: '0.1em',
        },
        toolHeading: {
            textTransform: 'uppercase',
            fontWeight: 'medium',
            fontSize: 0,
            color: 'darkGray',
            letterSpacing: '0.1em',
        },
        cardHeading: {
            fontSize: 4,
            fontWeight: 'medium',
            color: 'white',
        },
        label: {
            fontSize: 1,
            letterSpacing: '0.04em',
        },
        error: {
            fontSize: 1,
            color: 'invalid'
        }
    },
    variants: {
        avatar: {
            width: 'avatar',
            height: 'avatar',
            borderRadius: 'circle',
        },
        link: {
            color: 'primary',
        },
        inlineElement: {
            padding: '0.6em 0.7em 0.6em 0.3em',
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            textIndent: '1px',
            flexGrow: '1',
            outline: 'none',
            bg: 'black'
        },
        menuItem: {
            fontSize: 2,
            bg: 'transparent',
            fontWeight: 'medium',
            letterSpacing: '0.04em',
            display: 'inline-block',
            color: 'white',
            textDecoration: 'none',
            py: 2,
            px: 4,
            cursor: 'pointer',
            '::after': {
                display: 'inline-block',
                marginLeft: '.255em',
                verticalAlign: '.13em',
                content: '""',
                borderTop: '.3em solid',
                borderRight: '.3em solid transparent',
                borderBottom: '0',
                borderLeft: '.3em solid transparent',
            },
            WebkitTouchCallout: 'none',
            WebkitUserSelect: 'none',
            KhtmlUserSelect: 'none',
            MozUserSelect: 'none',
            MsUserSelect: 'none',
            userSelect: 'none',
        },
        nav: {
            variant: 'variants.menuItem',
            border: '1px solid transparent',
        },
        footer: {
            variant: 'variants.menuItem',
            fontWeight: 'body',
            border: '1px solid transparent',
            '::after': {
                display: 'none'
            }
        },
        menu: {
            variant: 'variants.menuItem',
            fontSize: 2,
            width: '100%',
            border: '1px solid transparent',
        },
        dropDown: {
            willChange: 'transform',
            top: '0px',
            left: '0px',
            marginTop: '0',
            float: 'none',
            zIndex: '1000'
        },
        transparentDropDown: {
            variant: 'variants.dropDown',
            position: 'absolute',
            bg: 'white',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.12)',
            py: 1,
            ':before': {
                content: '""',
                position: 'absolute',
                width: '3px',
                height: '3px',
                top: '-8px',
                left: '1.4em',
                borderWidth: '9px',
                borderColor: 'transparent #ffffff #ffffff transparent',
                borderStyle: 'solid',
                WebkitTransform: 'rotate(-135deg)',
                transform: 'rotate(-135deg)',
                boxShadow: '5px 5px 8px 0px rgba(0,0,0,0.05)',
            },
        },
        dark: {
            color: 'white',
            bg: 'darkestGray'
        },
        transparent: {
            color: ['white', 'white', 'primary'],
            bg: ['darkerGray', 'darkerGray', 'transparent']
        },
        transparentWhite: {
            color: ['primary', 'primary', 'white'],
            bg: ['darkerGray', 'darkerGray', 'transparent']
        },
    },
    buttons: {
        primary: {
            fontSize: 4,
            fontFamily: 'heading',
            fontWeight: 'medium',
            minWidth: '5em',
            color: 'white',
            bg: 'primary',
            borderRadius: 'default',
            boxShadow: 'button',
            cursor: 'pointer',
            ':hover,:focus': {
                bg: 'darkerPrimary',
                boxShadow: 'buttonHover',
                outline: 0
            },
            ':active': {
                bg: 'darkestPrimary',
                boxShadow: 'buttonHover',
                outline: 0
            },
        },
        outline: {
            variant: 'buttons.primary',
            color: 'primary',
            bg: 'transparent',
            boxShadow: '0 5px 11px 0 rgba(0, 0, 0, 0.10), 0 4px 15px 0 rgba(0, 0, 0, 0.08),0px 0px 0px 2px inset',
            ':hover,:focus': {
                bg: 'transparent',
                boxShadow: '0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15),0px 0px 0px 2px inset',
                color: 'darkerPrimary',
                outline: 0
            },
            ':active': {
                bg: 'transparent',
                boxShadow: '0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15),0px 0px 0px 2px inset',
                color: 'darkestPrimary',
                outline: 0
            },
        },
        heroOutline: {
            variant: 'buttons.primary',
            color: 'darkGray',
            bg: 'transparent',
            ml: '4px',
            mt: '1.5em',
            fontSize: [3, 3, 3, 4],
            py: [2, 2, 2, 3],
            px: 4,
            boxShadow: '0 5px 11px 0 rgba(0, 0, 0, 0.10), 0 4px 15px 0 rgba(0, 0, 0, 0.02),0px 0px 0px 2px inset',
            ':hover,:focus': {
                bg: 'transparent',
                boxShadow: '0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.05),0px 0px 0px 2px inset',
                color: 'darkerGray',
                outline: 0
            },
            ':active': {
                bg: 'transparent',
                boxShadow: '0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.05),0px 0px 0px 2px inset',
                color: 'darkestGray',
                outline: 0
            },
        },
        heroOutlineWhite: {
            variant: 'buttons.heroOutline',
            color: 'white',
            boxShadow: '0 5px 11px 0 rgba(0, 0, 0, 0.03), 0 4px 15px 0 rgba(0, 0, 0, 0.02),0px 0px 0px 2px inset',
            ':hover,:focus': {
                color: 'offWhite',
                boxShadow: '0 5px 11px 0 rgba(0, 0, 0, 0.03), 0 4px 15px 0 rgba(0, 0, 0, 0.02),0px 0px 0px 2px inset',
            },
            ':active': {
                color: 'offWhite',
                boxShadow: '0 5px 11px 0 rgba(0, 0, 0, 0.03), 0 4px 15px 0 rgba(0, 0, 0, 0.02),0px 0px 0px 2px inset',
            },
        },
        secondary: {
            variant: 'buttons.primary',
            bg: 'secondary',
            ':hover,:focus': {
                bg: 'darkerSecondary',
                boxShadow: 'buttonHover',
                outline: 0
            },
            ':active': {
                bg: 'darkestSecondary',
                boxShadow: 'buttonHover',
                outline: 0
            },
        },
        icon: {
            bg: 'transparent',
            cursor: 'pointer',
            fontSize: 24
        },
    },
    styles: {
        root: {
            fontFamily: 'body',
            fontWeight: 'body',
            lineHeight: 'body',
        },
    },
}

export default theme