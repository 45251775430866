//npm install --save ag-grid-enterprise

import React, { Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from "../utils/history";

const HomePage = React.lazy(() => import('../components/pages/HomePage/HomePage'));
const PrivateRoute = React.lazy(() => import('../utils/PrivateRoute'));
const AiDiagnosisLandingPage = React.lazy(() => import('../components/pages/AiDiagnosisLandingPage/AiDiagnosisLandingPage'));
const RecordsTablePage = React.lazy(() => import('../components/pages/RecordsTablePage/RecordsTablePage'));
const AiVideoDiagnosisPage = React.lazy(() => import('../components/pages/AiVideoDiagnosisPage/AiVideoDiagnosisPage'));
const AccountPage = React.lazy(() => import('../components/pages/AccountPage/AccountPage'));

//place below lines in a "../utils/history.js";
//import { createBrowserHistory } from "history";
//export default createBrowserHistory();

const App = () => {

  return (
    <Router history={history}>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/ai-diagnosis" component={AiDiagnosisLandingPage} />
          <PrivateRoute path="/records-table" component={RecordsTablePage} />
          <PrivateRoute path="/ai-diagnosis-tool" component={AiVideoDiagnosisPage} />
          <PrivateRoute path="/account" component={AccountPage} />
        </Switch>
      </Suspense>
    </Router >
  )
}

export default App