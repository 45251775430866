import { RECEIVE_USER } from '../actions/user';
import { CLEAR_USER } from '../actions/user';

export default function (state = null, action) {
    switch (action.type) {
        case RECEIVE_USER:
            return { ...action.user }
        case CLEAR_USER:
            return undefined
        default:
            return state
    }
};